<template>
  <v-form v-model="valid">
    <v-container class="pa-0" fluid>
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            v-model="localFormData.ROOM"
            :rules="[rules.required]"
            :label="$t('FORMS.DAMAGE_REPORT.ROOM') + ' *'"
            hide-details
            solo
            :append-icon="errors.room ? 'mdi-alert-circle-outline' : null"
            @update:error="errors = { ...errors, room: $event }"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-textarea
            v-model="localFormData.DETAILS"
            :rules="[rules.required]"
            :counter="1500"
            rows="3"
            :label="$t('FORMS.DAMAGE_REPORT.DETAILS') + ' *'"
            hide-details
            solo
            :append-icon="errors.details ? 'mdi-alert-circle-outline' : null"
            @update:error="errors = { ...errors, details: $event }"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-row dense class="text-center mt-3">
        <v-col cols="12">
          <p>
            <v-btn class="font-weight-regular text-none _box-shadow" small @click="takePicture">
              <v-icon left>mdi-camera-outline</v-icon>
              {{ $t("FORMS.DAMAGE_REPORT.ADD_PHOTO") }}
            </v-btn>
          </p>

          <p v-if="imageElement.src">
            <img :src="`data:image/jpeg;base64, ${imageElement.src}`" width="100%" />
          </p>
        </v-col>
      </v-row>

      <v-row dense text-center>
        <v-col cols="12">
          <slot name="button" :valid="valid" />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12">
          <slot name="description" />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";

import Rules from "@/app/modules/_global/classes/Rules";

export default {
  name: "DamageReportForm",

  model: {
    prop: "formData"
  },

  props: {
    formData: {
      required: true,
      type: Object
    }
  },

  data: () => ({
    localFormData: null,

    imageElement: {
      src: ""
    },

    valid: true,
    errors: {},

    rules: {
      required: Rules.required
    }
  }),

  watch: {
    localFormData: {
      deep: true,
      handler: "setFormData"
    }
  },

  created() {
    this.localFormData = Object.assign({}, this.formData);
  },

  methods: {
    async takePicture() {
      const image = await Camera.getPhoto({
        width: 400,
        quality: 90,
        allowEditing: false,
        resultType: CameraResultType.Base64,
        source: CameraSource.Prompt
      });
      // image.webPath will contain a path that can be set as an image src.
      // You can access the original file using image.path, which can be
      // passed to the Filesystem API to read the raw data of the image,
      // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
      const imageUrl = image.base64String;
      // Can be set to the src of an image now
      this.imageElement.src = imageUrl;
      this.localFormData.picture = imageUrl;
    },

    setFormData() {
      this.$emit("input", this.localFormData);
    }
  }
};
</script>
